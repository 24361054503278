<template>
<div class="payment-flow-method-step vuetify">
    <div class="payment-flow-step-title">{{ $translate('paymentFlow.steps.method') }}</div>
    <div class="payment-flow-step-prompt">{{ $translate('paymentFlow.method.prompt') }}</div>
    <div class="apple-pay" v-show="hasApplePayEnabled && canDisplayApplePay">
        <div class="interaction-feedback apple-pay-notification" v-if="processingErrorApplePay">
            {{ $translate('errors.paymentProcessingErrorApplePay') }}
        </div>
        <div id="applePayContainer" class="vuetify" style="display: none" @click="methodTypeIndex = -1">
            <!-- Apple Pay iFrame content will go here. -->
        </div>
    </div>
    
    <base-form>
        <base-expansion-panels v-model="methodTypeIndex" :mandatory="!(canDisplayApplePay && !method)">
            <base-expansion-panel v-if="(canDisplayGooglePay && !canDisplayApplePay)" :key="googlePayIndex" :selected="googlePayIndex === methodTypeIndex" class="google-pay-method">
                <template #title>
                    <div class="horizontal-fields">
                    <div><h1 class="saved-title">{{ $translate('labels.googlePay') }}</h1></div>
                    <base-spacer></base-spacer>
                    <div class="google-pay">
                        <base-icon class="digital-wallet-svg" icon="custom:GooglePaySVG"></base-icon>
                    </div>
                    </div>
                </template>
            </base-expansion-panel>
            <base-expansion-panel v-for="(method, index) in validSavedMethods" :key="savedMethodIndex(index)" :selected="savedMethodIndex(index) === methodTypeIndex" class="saved-payment-form">
                <template #title>
                    <div>
                        <h1 class="saved-title">{{ getMethodDescriptor(method) }}</h1>
                        <h2 v-if="method.formType=='card'" class="exp-date">
                            {{ $translate('labels.expDate', { date: $formatDate(method.expDate, 'MM/YY', 'MMYY') }) }}
                        </h2>
                    </div>
                    <base-spacer></base-spacer>
                    <div class="saved-svg">
                        <base-icon v-if="'Americanexpress' === method.cardType" class="card-svg" icon="custom:AmexSVG"></base-icon>
                        <base-icon v-else-if="'Discover' === method.cardType" class="card-svg" icon="custom:MastercardSVG"></base-icon>
                        <base-icon v-else-if="'Mastercard' === method.cardType" class="card-svg" icon="custom:DiscoverSVG"></base-icon>
                        <base-icon v-else-if="'Visa' === method.cardType" class="card-svg" icon="custom:VisaSVG"></base-icon>
                    </div>
                </template>
            </base-expansion-panel>
            <payment-flow-method-step-new-method-card
                v-if="cardProcessingAvailable"
                ref="paymentFlowMethodStepNewMethodCard"
                v-model="newCard"
                :selected="newCardIndex === methodTypeIndex"
                :isPaymentPlan="isPaymentPlan"
                :hideSaveOption="isGuestPay || isPaymentPlan"
                data-testid="payment-flow-method-step-new-method-card"
            ></payment-flow-method-step-new-method-card>
            <payment-flow-method-step-new-e-check-card
                v-if="eCheckProcessingAvailable"
                ref="paymentFlowMethodStepNewECheckCard"
                :selected="newECheckIndex === methodTypeIndex"
                :isPaymentPlan="isPaymentPlan"
                v-model="newECheck"
                :hideSaveOption="isGuestPay || isPaymentPlan"
                data-testid="payment-flow-method-step-new-e-check-card"
            ></payment-flow-method-step-new-e-check-card>
            <base-card outlined v-for="(method, index) in expiredMethods" :key="index + expiredCardIndex" class="expired-methods">
                <template #title>
                    <div class="horizontal-fields-exp">
                        <div class="first-of-type saved-svg"><base-icon class="vuetify" icon="mdi-alert-circle"></base-icon></div>
                        <div>
                            <h1 class="saved-title">{{ getMethodDescriptor(method) }}</h1>
                            <h2 v-if="method.formType=='card' && isExpired(method.expDate)" class="exp-date">
                                {{ $translate('labels.expired', { date: $formatDate(method.expDate, 'MM/YY', 'MMYY') }) }}
                            </h2>
                            <h2 v-else-if="method.formType=='card' && expiresBeforePaymentDate(method.expDate, date)" class="exp-date">
                                {{ $translate('labels.expires', { date: $formatDate(method.expDate, 'MM/YY', 'MMYY') }) }}
                            </h2>
                        </div>
                    </div>
                    <base-spacer></base-spacer>
                    <div v-if="isExpired(method.expDate)" class="saved-svg remove">
                        <a @click="deleteMethod(method)">{{ $translate('linkedAccounts.disassociate') }}</a>
                    </div>
                    <div v-else-if="expiresBeforePaymentDate(method.expDate, date)" class="saved-svg">
                        <base-icon v-if="'Americanexpress' === method.cardType" class="card-svg" icon="custom:AmexSVG"></base-icon>
                        <base-icon v-else-if="'Discover' === method.cardType" class="card-svg" icon="custom:MastercardSVG"></base-icon>
                        <base-icon v-else-if="'Mastercard' === method.cardType" class="card-svg" icon="custom:DiscoverSVG"></base-icon>
                        <base-icon v-else-if="'Visa' === method.cardType" class="card-svg" icon="custom:VisaSVG"></base-icon>
                    </div>
                </template>
            </base-card>
        </base-expansion-panels>
    </base-form>
    <div class="navigation-buttons">
        <base-btn @click="back()" class="back-btn" variant="outlined">{{ $translate('paymentFlow.actions.back') }}</base-btn>
        <base-spacer></base-spacer>
        <base-btn @click="cancel()" class="cancel-btn" variant="text">{{ $translate('paymentFlow.actions.cancel') }}</base-btn>
        <div data-testid="continue-btn-wrapper" @click="forceValidation()" class="continue-btn-wrapper">
            <base-btn ref="contButton" @click="updateMethod()" class="continue-btn v-button-primary method-step" :disabled="contButtonDisabled">{{ $translate('paymentFlow.actions.continue') }}</base-btn>
        </div>
    </div>
    <delete-payment-method-dialog
        ref="deletePaymentMethodDialog"
        @removeFromList="removeFromList"
    ></delete-payment-method-dialog>
</div>
</template>
<script>
import { getMethodDescriptor, CardUtils } from '../utils/utils';
import Provider from './../models/Provider';

import { formatDate, parseAmount } from './../utils/utils.js';

import PaymentFlowMethodStepNewECheckCard from './PaymentFlowMethodStepNewECheckCard.vue';
import PaymentFlowMethodStepNewMethodCard from './PaymentFlowMethodStepNewMethodCard.vue';
import BaseExpansionPanel from './BaseExpansionPanel.vue';
import moment from 'moment';
import BaseDialog from './BaseDialog.vue';
import BaseIcon from './BaseIcon.vue';
import BaseBtn from './BaseBtn.vue';
import BaseCard from './BaseCard.vue';
import BaseExpansionPanels from './BaseExpansionPanels.vue';
import BaseForm from './BaseForm.vue';
import BaseSpacer from './BaseSpacer.vue';
import DeletePaymentMethodDialog from './DeletePaymentMethodDialog.vue';

export default {
    name: 'PaymentFlowMethodStep',
    components: {
        BaseBtn,
        PaymentFlowMethodStepNewECheckCard,
        PaymentFlowMethodStepNewMethodCard,
        BaseExpansionPanel,
        BaseDialog,
        BaseIcon,
        BaseCard,
        BaseExpansionPanels,
        BaseForm,
        BaseSpacer,
        DeletePaymentMethodDialog
    },
    props: {
        amount: String,
        billing: Object,
        cardProcessingAvailable: Boolean,
        currentProvider: Provider,
        eCheckProcessingAvailable: Boolean,
        financingProcessingAvailable: Boolean,
        installmentCount: Number,
        method: Object,
        minAmountForTransaction: Number,
        savedMethods: Array,
        date: String,
        hasApplePayEnabled: Boolean,
        usaepayPublicKey: String,
        secureCode: String,
        estimateId: String,
        amountOptionType: String,
        canDisplayApplePay: Boolean,
        canDisplayGooglePay: Boolean
    },
    data: () => ({
        dialog: false,
        cancelDialog: false,
        methodTypeIndex: 0,
        methodToDelete: {},
        processingErrorApplePay: false,
        newBilling: {
            address: null,
            address2: null,
            city: null,
            email: null,
            phone: null,
            state: null,
            zip: null,
        },
        newCard: {
            firstName: null,
            lastName: null,
            number: null,
            expiration: null,
            cvv: null,
        },
        newECheck: {
            firstName: null,
            lastName: null,
            routingNumber: null,
            accountNumber: null,
            confirmAccountNumber: null,
            bankName: null,
        }
    }),
    computed: {
        FS() {
            return this.$store.getters.getFullstory;
        },
        googlePayIndex() {
            return (this.canDisplayGooglePay && !this.canDisplayApplePay) ? 0 : null;
        },
        newCardIndex() {
            return this.validSavedMethods.length + ((this.canDisplayGooglePay && !this.canDisplayApplePay) ? 1 : 0);
        },
        newECheckIndex() {
            return this.newCardIndex + (this.cardProcessingAvailable ? 1 : 0) + ((this.canDisplayGooglePay && !this.canDisplayApplePay) ? 1 : 0);
        },
        expiredCardIndex() {
            return this.newECheckIndex + 1;
        },
        numericAmount() {
            return parseFloat(this.amount);
        },
        selectedMethod() {
            if (this.methodTypeIndex == this.googlePayIndex) {
                return {
                    googlePay: {
                        cardType: null,
                        formType: 'card',
                        last4Digits: null,
                    }
                }
            } else if (this.methodTypeIndex < this.validSavedMethods.length) {
                return this.validSavedMethods[this.methodTypeIndex];
            } else if (this.cardProcessingAvailable && this.methodTypeIndex === this.newCardIndex) {
                return this.newCard;
            } else if (this.eCheckProcessingAvailable && this.methodTypeIndex === this.newECheckIndex) {
                return this.newECheck;
            }
        },
        validSavedMethods() {
            const cardMethods = [];
            const eCheckMethods = [];
            const financingMethods = [];
            if(this.savedMethods){
                this.savedMethods.forEach((method) => {
                    if (method) {
                        if (this.cardProcessingAvailable && 'card' === method.formType) {
                            if (!CardUtils.isExpired(method.expDate) && !CardUtils.expiresBeforePaymentDate(method.expDate, this.date)) {
                                cardMethods.push(method);
                            }
                        } else if (this.eCheckProcessingAvailable && 'echeck' === method.formType) {
                            eCheckMethods.push(method);
                        } else if (this.financingProcessingAvailable && 'financing' === method.formType) {
                            if (method.providerId === this.currentProvider.id) {
                                if (this.numericAmount >= this.minAmountForTransaction && this.numericAmount <= method.availableCredit) {
                                    if (!(this.installmentCount && this.installmentCount > 1)) {
                                        financingMethods.push(method);
                                    }
                                }
                            }
                        }
                    }
                });
            }
            return financingMethods.concat(cardMethods).concat(eCheckMethods); // order will be financing, credit cards, then echecks
        },
        expiredMethods() {
            const expiredMethods = [];
            if(this.savedMethods){
                this.savedMethods.forEach((method) => {
                    if (method) {
                        if (this.cardProcessingAvailable && 'card' === method.formType) {
                            if (CardUtils.isExpired(method.expDate) || CardUtils.expiresBeforePaymentDate(method.expDate, this.date)) {
                                expiredMethods.push(method);
                            }
                        }
                    }
                });
                expiredMethods.sort((a,b) => (moment(a.expDate, 'MMYY') <  moment(b.expDate, 'MMYY')) ? 1 : -1);
            }
            return expiredMethods;
        },
        isPaymentPlan() {
            if (this.installmentCount) {
                return this.installmentCount > 1;
            }
            return false;
        },
        scheduledToday() {
            const today = new Date();
            return formatDate(today, 'MMMM D, YYYY', null) === formatDate(this.date, 'MMMM D, YYYY', null);
        },
        contButtonDisabled() {
            if (this.methodTypeIndex == this.newCardIndex && !this.newCard.valid) {
                return true;
            } else if ( this.methodTypeIndex == this.newECheckIndex && !this.newECheck.valid) {
                return true;
            } else if (this.methodTypeIndex == -1) {
                return true;
            }
            return false;
        },
        isGuestPay() {
            return this.$store.getters.isGuestPay;
        }
    },
    methods: {
        savedMethodIndex(index) {
            return index + ((this.canDisplayGooglePay && !this.canDisplayApplePay) ? 1 : 0);
        },
        forceValidation() {
            if (this.contButtonDisabled) {
                if (this.$refs.paymentFlowMethodStepNewMethodCard.selected) {
                    this.$refs.paymentFlowMethodStepNewMethodCard.validateFields();
                } else if (this.$refs.paymentFlowMethodStepNewECheckCard.selected) {
                    this.$refs.paymentFlowMethodStepNewECheckCard.validateFields();
                }
            }
        },
        updateMethod() {
            if (this.selectedMethod?.googlePay) {
                this.$emit('update-method', {
                    billing: null,
                    method: {
                        googlePay: this.selectedMethod.googlePay
                    },
                });
            } else if (this.selectedMethod?.id) {
                this.$emit('update-method', {
                    billing: null,
                    method: {
                        savedMethod: this.selectedMethod,
                    },
                });
            } else if (this.selectedMethod?.routing) {
                this.$emit('update-method', {
                    billing: this.selectedMethod.billing,
                    method: {
                        eCheck: this.selectedMethod,
                    },
                }); 
            } else if (this.selectedMethod?.number) {
                this.$emit('update-method', {
                    billing: this.selectedMethod.billing,
                    method: {
                        card: this.selectedMethod,
                    },
                }); 
            }
        },
        cancel() {
            this.$emit('cancel');
        },
        back() {
            this.$emit('back');
        },
        getMethodDescriptor,
        isExpired: CardUtils.isExpired,
        expiresBeforePaymentDate: CardUtils.expiresBeforePaymentDate,
        deleteMethod(method) {
            if (this.$refs && this.$refs.deletePaymentMethodDialog) {
                this.$refs.deletePaymentMethodDialog.open(method);
            }
        },
        removeFromList(method) {
            var index = this.savedMethods.indexOf(method);
            this.savedMethods.splice(index, 1);
        },
        getApplePayMethod() {
            /***
             * Apple Pay enabled through provider flag
             * Not implemented for payment plans or estimates yet
             */
            var client = new window.usaepay.Client(this.usaepayPublicKey);
            var applePayConfig = {
                targetDiv: 'applePayContainer',
                displayName: 'Patientco',
                paymentRequest: {
                    total: {
                        label: this.currentProvider.name,
                        amount: this.amount,
                        type: 'final'
                    },
                    requiredBillingContactFields: [
                        'postalAddress'
                    ],
                    requiredShippingContactFields: [
                        'name',
                        'phone',
                        'email'
                    ],
                    countryCode: 'US',
                    currencyCode: 'USD'
                }
            };
            var applePay = client.createApplePayEntry(applePayConfig);
            applePay.addButton();
            var applePayContainer = document.getElementById('applePayContainer');
            if (applePayContainer) {
                applePayContainer.style.display = 'inline-block';
            }
            applePay.checkCompatibility();
            const onApplePaySuccess = () => {
                // once successfully completed, payment key is ready
                client.getPaymentKey(applePay).then((token) => {
                    this.$store.dispatch('postApplePayToken', {
                        amount: parseAmount(this.amount),
                        date: formatDate(this.date, 'YYYY/MM/DD', 'YYYY-MM-DD'),
                        applePayToken: token,
                        secureCode: this.secureCode,
                        paymentType: this.amountOptionType,
                        estimateId: this.estimateId
                    }).then((result) => {
                        this.emitter.emit('feedbackModal:showPrompt', {
                            receipt: result,
                            providerName: this.currentProvider.name
                        });
                        this.$router.push({
                            name: 'Receipt',
                            params: {
                                paymentId: result.paymentId,
                                providerId: result.providerId,
                            },
                        });
                    })
                    .catch((err) => {
                        console.error("Error running onApplePaySuccess", err);
                        this.processingErrorApplePay = true;
                    });
                });
            };
            applePay.on('applePaySuccess', onApplePaySuccess);
            this.emitter.on('mockApplePaySuccess', onApplePaySuccess);
        },
    },
    created() {
        this.$emit('scroll-to-top');
    },
    mounted() {
        if (this.canDisplayApplePay) {
            if (!this.method) {
                // Makes none of the options selected since Apple Pay
                // should be first and should appear selected
                this.methodTypeIndex = -1;
            }
            this.getApplePayMethod();
        }
        if (this.method) {
            if (this.method.savedMethod && this.validSavedMethods.includes(this.method.savedMethod)) {
                this.methodTypeIndex = this.validSavedMethods.indexOf(this.method.savedMethod) + ((this.canDisplayGooglePay && !this.canDisplayApplePay) ? 1 : 0);
            } else if (this.cardProcessingAvailable && this.method.card) {
                Object.keys(this.method.card).forEach((key) => {
                    this.newCard[key] = this.method.card[key];
                });
                this.methodTypeIndex = this.newCardIndex;
            } else if (this.eCheckProcessingAvailable && this.method.eCheck) {
                Object.keys(this.method.eCheck).forEach((key) => {
                    this.newECheck[key] = this.method.eCheck[key];
                });
                this.methodTypeIndex = this.newECheckIndex;
            }
        }
        this.$emit('loaded');
    },
};
</script>
<style lang="scss">
@import '../styles/variables.scss';
.card-svg {
    max-width: 3rem;
    max-height: 1.9rem;
    margin: 0.25rem;
}

.payment-flow-method-step.vuetify {
    .v-expansion-panel-title {
        svg {
            font-size: 3rem;
            margin: 0.25rem;
        }

    }

    .interaction-feedback.apple-pay-notification {
        background: none;
        padding: 0;
    }

    .saved-title {
        font-size: 1.6rem;
        font-weight: 500;
	    line-height: 2.4rem;
    }

    .exp-date {
        font-size: 1.2rem;
	    line-height: 1.6rem;
    }
    .saved-svg {
        padding-top: 5.75px;
    }

    .expired-methods.v-card {
        width: 100%;
        margin: 0;
        padding: 0.9rem 0;
        margin-bottom: 1.5rem;

        .v-card-title {
            padding: 2rem 2.4rem 1rem 4rem;
            color: $global-color-font-new;
            padding-left: 0;
            font-size: 1.4rem;
            letter-spacing: 0.046rem;
            line-height: 1.6rem;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            @include wider-than-tablet {
                font-size: 1.6rem;
                letter-spacing: 0.014rem;
                line-height: 2.4rem;
            }
        }
        .saved-title {
            color: $global-color-greyed-out
        }
        .exp-date {
            color: #CE1818;
        }
        .v-icon.v-icon {
            color: #CE1818;
        }
        .remove {
            text-decoration: underline;
            font-size: 1.2rem;
            line-height: 1.6rem;
            @include wider-than-tablet {
                font-size: 1.4rem;
                line-height: 2rem;
            }
        }
    }

    .expired-methods.v-card:last-child {
        margin-bottom: 0;
    }

    .expired-methods.v-card.v-sheet.theme--light {
        border: 1px solid $global-color-divider;
    }

    .google-pay-method.v-expansion-panel {
        width: 100%;
        div {
            width: 100%;
        }
        .horizontal-fields {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: center;

            div:last-of-type {
                justify-content: end;
                text-align: right;
            }
        }
    }

    .digital-wallet-svg {
        width: 12rem;
        height: 6.75rem;
        margin: 0;
    }

    .horizontal-fields-exp {
        padding-left: 2.4rem;
        display: flex;
        flex-flow: row;
        text-align: left;

        .first-of-type {
            padding-right: 1.6rem;
        }
    }
}
</style>
